@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts";
@import "colors";
@import "variables";

html {
    font-size: 62.5%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    body {
        font-size: 100%;
        flex: 1;
        margin: 0;
        font-size: 1.6rem;
        font-weight: 400;
        font-family: var(--font-primary);
        display: flex;
        flex-direction: column;
        color: var(--general-color, #000);
    }

    #root {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: var(--general-background, #fff);
    }

    * {
        box-sizing: inherit;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }
}

#g-recaptcha {
    display: flex;
    justify-content: center;
}

input:focus-visible {
    outline-color: var(--primary-color);
}

.card {
    background: #fff;
    border: solid .1rem var(--color-gray-3);
    border-radius: var(--general-border-radius);
}

table {
    table-layout: fixed;

    thead {
        tr {
            border-bottom: solid .1rem var(--color-gray-3);

            th {
                font-weight: 500;
                padding: 1rem 2rem;
                text-align: left;
                color: var(--color-gray-1);
                text-transform: uppercase;
                font-size: 1.4rem;
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 1rem 2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:nth-child(even) {
                background-color: var(--color-gray-4);
            }
        }

        tr+tr {
            border-top: solid .1rem var(--color-gray-3);
        }
    }
}