@import "config";
@import "../../styles/mixins";

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.viewport {
  overflow: auto;
  position: relative;
  contain: layout style paint;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
}

.header {
  @extend .sticky;
  top: 0;
  min-width: 100%;
  float: left;

  .columm {
    padding: 1rem;
  }

  .title {
    @include truncate;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.footer {
  @extend .sticky;
  bottom: 0;
  min-width: 100%;
  float: left;
}

.table {
  position: relative;
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }

  .row {
    display: flex;
    position: relative;
    min-width: 100%;

    .freezedColumnsWrapper {
      position: sticky;
      left: 0;
      z-index: 2;
      display: flex;
    }

    .columnsWrapper {
      display: flex;
      flex-grow: 1;
    }
  }

  .column {
    flex-shrink: 0;
  }

  .resizableColumn {
    position: relative;
  }

  .resizeHandler {
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(-100%);
    cursor: ew-resize;
    z-index: 20;

    &:hover {
      background-color: var(--table-column-resizer-color);
    }

    &.active {
      display: none;
    }
  }

  .lineResizer {
    height: 100%;
    border-right: solid .3rem var(--table-column-resizer-color);
    background-color: rgba(0, 0, 0, .05);
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translate3d(0, 0, 0);
    ;
  }

  .resizer {
    pointer-events: none;
    background-color: rgba(0, 0, 255, .2);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translateZ(0);
    border: dashed 1px var(--table-column-resizer-color);
    opacity: 1;
    animation: fadeIn 120ms ease 1 forwards;
  }
}

.editable {
  cursor: text;
  width: 100%;

  &.truncate {
    @extend .ellipsis;
  }

  input {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }
}

.stickyScrollbar {
  position: sticky;
  bottom: 0;
  overflow-x: auto;

  .scrollbarTrack {
    height: 1px;
  }
}

.headerMenu {
  background-color: #fff;
  border-radius: var(--general-border-radius);
  box-shadow: 0 .3rem .3rem rgba(0, 0, 0, .05);
  border: solid .1rem var(--color-gray-3);
  color: var(--color-purple-1);
  font-size: 1.4rem;

  .columnOptions {
    padding: 1rem 1.4rem;

    .legend {
      text-transform: uppercase;
      color: var(--color-gray-1);
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: .5rem;
      display: flex;
    }

    ul {

      li {
        padding: .3rem 0;
      }
    }
  }

  .columnOptions+.columnOptions {
    border-top: solid .1rem var(--color-gray-3);
  }

  .searchInput {
    background-color: #efefef;
    padding: .5rem .7rem;
    font-family: var(--font-primary);
    font-size: 1.4rem;
    min-width: 15rem;
  }

  .sortOption {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.sorted {
      color: var(--primary-color);
    }

    i {
      margin-left: 4rem;
      font-size: 1.4rem;
    }
  }
}

[data-virtualized-header] [data-row="true"] {
  border-bottom: solid .1rem var(--color-gray-3);
  text-align: left;
  color: var(--color-gray-1);
  background-color: var(--color-gray-4);

  &:hover {
    :not([data-sticky-columns-wrapper="true"]) [data-column]:not(.filtered) {
      background-color: #fff;
    }
  }

  [data-column] {
    background-color: #fff;

    &.filtered {
      background-color: var(--primary-color);
      color: white;
    }
  }
}

[data-column] {
  border-right: solid .1rem var(--color-gray-3);

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

[data-row="true"] {
  border-bottom: solid .1rem var(--color-gray-3);

  [data-column] {
    background-color: #fff;
  }

  &:hover {
    [data-column] {
      background-color: #efefef;
    }

    &.even {
      [data-column] {
        background-color: #efefef;
      }
    }
  }

  &.even {
    [data-column] {
      background-color: var(--color-gray-4);
    }

    [data-sticky-columns-wrapper="true"] {
      [data-column] {
        background-color: #efefef;
      }
    }
  }

  [data-sticky-columns-wrapper="true"] {
    [data-column] {
      background-color: #fafafa;
    }
  }
}

[data-virtualized-scrolled-y="true"] .header {
  box-shadow: 0 0 .3rem rgba(0, 0, 0, .25);
}

[data-virtualized-scrolled-x="true"] [data-sticky-columns-wrapper="true"] {
  position: relative;

  &:after {
    transform: translateX(-.1rem);
    box-shadow: 0 0 .3rem rgba(0, 0, 0, .25);
    width: .1rem;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    z-index: -1;
  }
}