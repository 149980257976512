:root {
    --primary-color: var(--color-blue-1);
    --typo-purple-blue-background: var(--color-purple-1);
    --typo-purple-white-background: var(--color-purple-2);
    --logo-gray-white-background: var(--color-gray-1);
    --logo-gray-blue-background: var(--color-gray-2);

    /**
     *  Basic Colors
     **/
    --color-purple-1: #0A0A35;
    --color-purple-2: #1A2365;
    --color-purple-3: #3A0CA3;

    --color-green: #00b368;

    --color-blue-1: #3A4EF5;
    --color-blue-2: #4895EF;
    --color-blue-3: #4BC9F0;

    --color-gray-1: #A0A0A0;
    --color-gray-2: #BBBBBB;
    --color-gray-3: #DBDBDB;
    --color-gray-4: #FAFAFA;
    --color-gray-5: #6B7280;

    --color-white: #FFFFFF;

    --color-pink: #b5189e;

    --color-yellow: #FAE800;

    /**
     *  Undefined Colors
     **/
    --color-red: #fe4242;
}