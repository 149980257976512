.container {
    position: relative;
}

.menu {
    position: absolute;
    min-width: 120%;
    z-index: 1;
    transform-origin: top;

    &.top {
        bottom: 100%;
        margin-bottom: .5rem;
    }

    &.bottom {
        top: 100%;
        margin-top: .5rem;
    }

    &.left {
        right: 0;
    }

    &.right {
        left: 0;
    }

    >ul {
        border: solid .1rem var(--color-gray-3);
        background-color: #fff;
        box-shadow: 0 .3rem .3rem rgba(0, 0, 0, .05);
        border-radius: var(--general-border-radius);
        padding: .5rem 0;

        &:not(:last-child) {
            border-radius: var(--general-border-radius) var(--general-border-radius) 0 0;
            box-shadow: none;
        }

        &:not(:last-child):not(:first-child) {
            border-top: none;
            border-radius: 0;
        }

        &:last-child:not(:first-child) {
            border-top: none;
            border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
        }

        li {
            cursor: pointer;
            font-size: 1.4rem;
            padding: .7rem 2.4rem .7rem 1rem;
            display: flex;
            align-items: center;
            color: var(--color-purple-2);
            width: 100%;
            white-space: nowrap;

            &.disabled {
                opacity: .5;
                cursor: not-allowed;
            }

            &:hover:not(.disabled) {
                background-color: var(--primary-color);
                color: #fff;

                i {
                    color: var(--color-purple-2);
                }
            }
        }

        li+li {
            border-top: solid .1rem #efefef;
        }
    }
}