@keyframes slide {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}


.indicator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: .3rem;
    overflow: hidden;
    z-index: 200;

    &.absolute {
        position: absolute;
    }

    &:after {
        content: "";
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        position: absolute;
        top: 0;
        left: 0;
        animation: slide 1s ease infinite;
    }
}